<template>
  <div id="view-admin-product-managment">
    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">Product Management</div>
            <div class="level">
              <div class="level-left">
                <b-select
                  v-model="perPage"
                  :disabled="!isPaginated"
                  class="level-item"
                >
                  <option value="5">5 per page</option>
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="20">20 per page</option>
                </b-select>
              </div>
              <div class="level-right">
                <router-link
                  to="/admin/products/edit"
                  class="button is-primary level-item  is-light"
                  >New Product</router-link
                >
              </div>
            </div>
            <section>
              <b-table
                :data="list"
                :paginated="isPaginated"
                @page-change="onPageChange"
                backend-pagination
                :total="recordCount"
                :per-page="perPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                striped
                default-sort="name"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                icon-pack="fas"
              >
                <b-table-column
                  field="productId"
                  label="ID"
                  width="30"
                  numeric
                  v-slot="props"
                >
                  {{ props.row.productId }}
                </b-table-column>

                <b-table-column
                  field="product.name"
                  width="auto"
                  label="Name"
                  v-slot="props"
                >
                  {{ props.row.name }}
                </b-table-column>

                <b-table-column
                  field="short"
                  label="Short"
                  width="160"
                  v-slot="props"
                >
                  {{ props.row.short }}
                </b-table-column>

                <b-table-column label="Tools" v-slot="props" sticky width="145">
                  <span>
                    <router-link
                      :to="'/admin/products/edit/' + props.row.short"
                      class="button is-small is-primary is-light mr-1"
                      >Edit</router-link
                    >
                    <router-link
                      :to="'/admin/products/delete/' + props.row.short"
                      class="button is-small is-danger is-light"
                      >Delete</router-link
                    >
                  </span>
                </b-table-column>
              </b-table>
            </section>
          </div>
          <div class="footnotes is-hidden">
            <ol>
              <li id="fn:1"></li>
            </ol>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Products from "@/Druware.Web.Shared/api/api";

const url = "/api/product/";

export default {
  name: "view-admin-product-managment",
  components: {},
  data: function() {
    return {
      session: {},
      list: [],
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 10
    };
  },
  methods: {
    onEdit(obj) {
      this.editItem = obj;
      this.isEditDialogActive = true;
    },
    onEditComplete() {
      this.getList();
      this.isEditDialogActive = false;
    },
    onEditCancel() {
      this.isEditDialogActive = false;
    },
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    }
  },
  mounted() {
    Products.list(
      url,
      this,
      function(obj, data) {
        obj.list = data.list;
      },
      function(obj, err) {
        obj.showError(err);
      }
    );
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-support {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-support {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
