<template>
  <div class="component-article-summary">
    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-64x64">
            <img :src="iconForType(internal)" alt="Image" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">{{ internal.title }}</div>
            <span v-html="compileMarkdown(internal.body)"></span>
          </div>
          <div class="level is-mobile">
            <div class="level-left">
              <small class="level-item">
                {{ internal.byLine }}
                @ {{ formatDate(internal.posted) }}
              </small>
            </div>

            <div class="level-right">
              <b-taglist v-if="article != null" class="level-item">
                <b-tag type="is-light" v-for="item in article.tags" :key="article.tags.indexOf(item)">{{item}}</b-tag>
              </b-taglist>
              <span v-if="hideTools == false">
                <a
                  class="level-item left"
                  :href="'/article/' + internal.permalink"
                  aria-label="view article"
                >
                  <span class="icon is-small">
                    <i class="fas fa-search" aria-hidden="true"></i>
                  </span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import Marked from "marked";

export default {
  name: "component.article.summary",
  props: {
    article: Object,
    session: Object,
    hideTools: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      internal: {}
    };
  },
  watch: {
    article: function(val) {
      this.internal = val;
    }
  },
  methods: {
    formatDate(str) {
      if (str == null) return "";
      var d = new Date(str);
      return (
        d.toLocaleDateString("en-US") + "  " + d.toLocaleTimeString("en-US")
      );
    },
    compileMarkdown(str) {
      if (str == null) return "";
      return Marked(str);
    },
    iconForType(article) {
      if (article.type == null) return "";
      return article.type == null
        ? "/icon/article.gif"
        : "/api/asset/content/" + article.type.icon.fileName;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media screen and (min-width: 1px) {
  .component-article-summary {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
    }
  }
}
@media screen and (min-width: 767px) {
  .component-article-summary {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
