<template>
  <div id="view-admin-product-edit">
    <div class="media left">
      <div class="media-left is-hidden-touch">
        <figure class="image is-48x48">
          <img src="/icon/confg_48x48.png" alt="Configuration Icon" />
        </figure>
      </div>
      <div class="media-content">
        <form action="" @submit="onSubmit">
          <b-tabs
            v-model="activeTab"
            size="is-small"
            type="is-boxed"
            position="is-centered"
          >
            <b-tab-item label="Article">
              <div class="content">
                <b-field label="Title" label-position="on-border">
                  <b-input
                    ref="title"
                    v-model="article.title"
                    required
                    autofocus
                    :disabled="disabled"
                    @blur="onTitleChange"
                  ></b-input>
                </b-field>
                <b-field label="Permalink" label-position="on-border">
                  <b-input
                    v-model="article.permalink"
                    required
                    :disabled="disabled"
                  ></b-input>
                </b-field>
                <b-field label="Summary" label-position="on-border">
                  <b-input
                    v-model="article.summary"
                    type="textarea"
                    maxlength="2014"
                    :disabled="disabled"
                  ></b-input>
                </b-field>
                <b-field label="Body" label-position="on-border">
                  <b-input
                    v-model="article.body"
                    required
                    type="textarea"
                    @blur="onBodyChange"
                    :disabled="disabled"
                  ></b-input>
                </b-field>

                <div class="columns">
                  <div class="column">
                    <b-field label="Tags" label-position="on-border">
                      <b-taginput
                        v-model="article.tags"
                        :data="allTags"
                        autocomplete
                        :allow-new="false"
                        placeholder=""
                        :disabled="disabled"
                        :open-on-focus="true"
                        @typing="getFilteredTags"
                      ></b-taginput>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Posted" label-position="on-border">
                      <b-datetimepicker
                        v-model="postedDate"
                        editable
                        :disabled="disabled"
                      ></b-datetimepicker>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="Preview">
              <article-detail
                :session="session"
                :article="article"
                hideTools
              ></article-detail>
            </b-tab-item>
          </b-tabs>
          <div class="level is-mobile">
            <div class="level-left">
              <small v-if="error != ''" class="level-item is-danger">
                {{ error }}
              </small>
            </div>
            <div class="level-right">
              <div class="field is-grouped">
                <button
                  type="sumbit"
                  class="button is-link"
                  :disabled="disabled"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- /b-card -->
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import ArticleDetail from "@/Druware.Web.Shared/components/ArticleDetail";

export default {
  name: "view-admin-article-edit",
  components: {
    ArticleDetail
  },
  data() {
    return {
      article: {},
      postedDate: null,
      types: [],
      disabled: false,
      activeTab: undefined,
      error: null,
      allTags: []
    };
  },
  props: {
    session: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    getFilteredTags(text) {
      var result = this.allTags.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });
      return result;
    },
    stringsForTags(tagList) {
      var result = [];
      tagList.forEach(function(item) {
        result.push(item.tag != null ? item.tag.name : item.name);
      });
      return result;
    },
    onSubmit(e) {
      e.preventDefault();
      if (this.session.login != null) {
        this.disabled = true;
        this.article.posted = this.postedDate;
        if (this.$route.params.permalink != null) {
          // edit
          API.put(
            API.url.news,
            this,
            this.articleId,
            this.article,
            function(obj, data) {
              obj.disabled = false;
              obj.article = data;
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$router.back();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        } else {
          // add
          API.post(
            API.url.news,
            this,
            this.article,
            function(obj, data) {
              obj.disabled = false;
              obj.article = data;
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$router.back();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        }
      }
    },
    onCancel() {
      this.$router.back();
    },
    onTitleChange() {
      if (this.article == null && this.article.title == null) return;
      if (this.article.permalink == "" || this.article.permalink == null) {
        var link = this.article.title;
        link = link.toLowerCase();
        link = link.replace(/ /g, "_");
        link = link.replace(/,/g, "");
        link = link.replace(/\./g, "");
        link = link.replace(/\*/g, "");
        link = link.replace(/\[/g, "");
        link = link.replace(/\]/g, "");
        link = encodeURIComponent(link);
        this.article.permalink = link;
      }
    },
    onBodyChange() {
      if (this.article == null) return;
      if (this.article.summary == "" || this.article.summary == null) {
        this.article.summary = this.article.body.substring(0, 2014);
      }
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  mounted() {
    // TODO: Tags and a tag box
    API.list(
      API.url.base.tag,
      this,
      function(obj, data) {
        // alter the tags from the list to an array of strings.
        obj.allTags = obj.stringsForTags(data.list);
      },
      function(obj, err) {
        obj.showMessage(err);
      }
    );
    if (this.$route.params.permalink != null) {
      API.get(
        API.url.news,
        this,
        this.$route.params.permalink,
        function(obj, data) {
          obj.article = data;
          obj.postedDate = new Date(data.posted + "Z");
        },
        function(obj, err) {
          obj.showMessage("Unable to get article: " + err, "is-danger");
        }
      );
      return;
    }
    API.model(
      API.url.news,
      this,
      function(obj, data) {
        obj.article = data;
        obj.article.authorId = obj.session.login.loginId;
        obj.article.byLine =
          obj.session.login.lastName + ", " + obj.session.login.firstName;
      },
      function(obj, err) {
        obj.showMessage("Unable to get model: " + err, "is-danger");
      }
    );
  }
};
</script>
