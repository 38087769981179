<template>
  <div id="view-admin-picklist-type-edit">
    <b-card :title="this.displayName + ' Type Edit'" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" alt="Configuration Icon" />
          </figure>
        </div>
        <div class="media-content">
          <form action="" @submit="onSubmit">
            <div class="content">
              <b-field label="Name" label-position="on-border">
                <b-input
                  ref="name"
                  v-model="tag.name"
                  required
                  autofocus
                  :disabled="disabled"
                ></b-input>
              </b-field>
            </div>
            <div class="level is-mobile">
              <div class="level-left"></div>
              <div class="level-right">
                <div class="field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link mr-1"
                    :disabled="disabled"
                  >
                    OK
                  </button>
                  <a
                    @click="onCancel()"
                    class="button is-light"
                    :disabled="disabled"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-admin-tag-edit",
  components: {
    BCard
  },
  data() {
    return {
      displayName: "",
      tag: "",
      model: {},
      disabled: false,
      activeTab: undefined,
      error: null
    };
  },
  props: {
    edit: Object,
    session: Object
  },
  methods: {
    getModel() {
      API.model(
        API.url.base.tag,
        this,
        function(obj, data) {
          obj.tag = data;
        },
        function(obj, err) {
          obj.showMessage("Unable to get model: " + err, "is-danger");
        }
      );
    },
    onSubmit(e) {
      e.preventDefault();
      if (this.session.login != null) {
        this.disabled = true;
        if (this.edit != null) {
          // edit
          API.put(
            API.url.base.tag,
            this,
            this.tag.tagId,
            this.tag,
            function(obj, data) {
              obj.disabled = false;
              obj.tag = data;
              obj.model = obj.getModel();
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$emit("onComplete", null);
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        } else {
          // add
          API.post(
            API.url.base.tag,
            this,
            this.tag,
            function(obj, data) {
              obj.disabled = false;
              obj.tag = data;
              obj.model = obj.getModel();
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$emit("onComplete", null);
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        }
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  watch: {
    edit(to, from) {
      if (to != from) {
        this.tag = to;
      }
      if (this.tag == null) {
        this.getModel();
      }
    }
  },
  mounted() {
    if (this.edit != null) {
      this.tag = this.edit;
    } else {
      this.getModel();
    }
  }
};
</script>
