import { render, staticRenderFns } from "./AssetList.vue?vue&type=template&id=b0ce8564&scoped=true"
import script from "./AssetList.vue?vue&type=script&lang=js"
export * from "./AssetList.vue?vue&type=script&lang=js"
import style0 from "./AssetList.vue?vue&type=style&index=0&id=b0ce8564&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0ce8564",
  null
  
)

export default component.exports