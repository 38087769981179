<template>
  <div id="view-admin-product-edit">
    <b-card title="Type Edit" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" alt="Configuration Icon" />
          </figure>
        </div>
        <div class="media-content">
          <form action="" @submit="onSubmit">
            <b-tabs
              v-model="activeTab"
              size="is-small"
              type="is-boxed"
              position="is-centered"
            >
              <b-tab-item label="Type">
                <div class="content">
                  <b-field label="Description" label-position="on-border">
                    <b-input
                      ref="title"
                      v-model="type.description"
                      required
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>
            <div class="level is-mobile">
              <div class="level-left">
                <small v-if="error != ''" class="level-item is-danger">
                  {{ error }}
                </small>
              </div>
              <div class="level-right">
                <div class="field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link"
                    :disabled="disabled"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-admin-asset-edit",
  components: {
    BCard
  },
  data() {
    return {
      type: {},
      assets: [],
      disabled: false,
      activeTab: undefined,
      error: null
    };
  },
  props: {
    session: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (this.session.login != null) {
        this.disabled = true;
        if (this.$route.params.typeId != null) {
          // edit
          API.put(
            API.url.base.assetType,
            this,
            this.type.typeId,
            this.type,
            function(obj, data) {
              obj.disabled = false;
              obj.type = data;
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$router.back();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        } else {
          // add
          API.post(
            API.url.base.assetType,
            this,
            this.type,
            function(obj, data) {
              obj.disabled = false;
              obj.type = data;
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$router.back();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        }
      }
    },
    onCancel() {
      this.$router.back();
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  mounted() {
    if (this.$route.params.typeId != null) {
      API.get(
        API.url.base.assetType,
        this,
        this.$route.params.typeId,
        function(obj, data) {
          obj.type = data;
        },
        function(obj, err) {
          obj.showMessage("Unable to get type: " + err, "is-danger");
        }
      );
      return;
    }
    API.model(
      API.url.base.assetType,
      this,
      function(obj, data) {
        obj.type = data;
      },
      function(obj, err) {
        obj.showMessage("Unable to get model: " + err, "is-danger");
      }
    );
  }
};
</script>
