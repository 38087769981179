<template>
  <div id="view-admin">
    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title">Admin</div>
            <section class="section" v-if="showBase">
              <h2 class="subtitle">Base</h2>
              <div class="tile is-ancestor is-size-7 has-text-centered">
                <div class="tile is-12 is-parent">
                  <div class="tile is-4 is-child">
                    <router-link
                      class="box ml-3 mr-3 mb-3 has-background-info-light"
                      to="/admin/asset"
                    >
                      Asset Management
                    </router-link>
                  </div>
                  <div class="tile is-4 is-child">
                    <router-link
                      class="box ml-3 mr-3 mb-3 has-background-info-light"
                      to="/admin/tag"
                    >
                      Tag Management
                    </router-link>
                  </div>
                </div>
              </div>
            </section>
            <section class="section">
              <h2 class="subtitle">Authentication</h2>
              <div class="tile is-ancestor is-size-7 has-text-centered">
                <div class="tile is-12 is-parent">
                  <div class="tile is-4 is-child">
                    <router-link
                      class="box ml-3 mr-3 mb-3 has-background-info-light"
                      to="/admin/user"
                    >
                      User Management
                    </router-link>
                  </div>
                  <div class="tile is-4 is-child">
                    <router-link
                      class="box ml-3 mr-3 mb-3 has-background-info-light"
                      to="/admin/sessions"
                    >
                      Session Management
                    </router-link>
                  </div>
                </div>
              </div>
            </section>
            <section class="section">
              <h2 class="subtitle">Website Tools</h2>
              <div class="tile is-ancestor is-size-7 has-text-centered">
                <div class="tile is-12 is-parent">
                  <div class="tile is-4 is-child">
                    <router-link
                      class="box ml-3 mr-3 mb-3 has-background-info-light"
                      to="/admin/news"
                    >
                      News Management
                    </router-link>
                  </div>
                  <div class="tile is-4 is-child">
                    <router-link
                      class="box ml-3 mr-3 mb-3 has-background-info-light"
                      to="/admin/products"
                    >
                      Product Management
                    </router-link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
// import Session from '@/api/session'

// TODO: add security checks

export default {
  name: "view-admin",
  components: {},
  data: function() {
    return {
      session: {}
    };
  },
  computed: {
    showBase: function() {
      return this.session.login.access.includes(
        "FBF82F93-CC44-4EF2-9C05-5DD906CC0C5A"
      );
    }
  },
  beforeMount() {
    this.session = this.$attrs.session;
    if (this.session.login == null) {
      this.$router.push("/");
      return;
    }
    if (this.session.login.access == null) {
      this.$router.push("/");
      return;
    }
    if (
      !this.session.login.access.includes(
        "6d736fa5-cb3d-48d8-a6c9-5affacef8b2f"
      )
    ) {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-support {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-support {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
