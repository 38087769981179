f<template>
  <div id="view-admin-product-edit">
    <b-card title="Product Edit" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" alt="Configuration Icon" />
          </figure>
        </div>
        <div class="media-content">
          <b-tabs
            v-model="activeTab"
            size="is-small"
            type="is-boxed"
            position="is-centered"
          >
            <b-tab-item label="Product">
              <form action="" @submit="onSubmit">
                <div class="content">
                  <b-field label="Name" label-position="on-border">
                    <b-input
                      ref="product-name"
                      v-model="product.name"
                      placeholder="Enter the product name"
                      required
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Short" label-position="on-border">
                    <b-input
                      v-model="product.short"
                      placeholder="Enter the product short"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Icon URL" label-position="on-border">
                    <b-input
                      v-model="product.iconUrl"
                      placeholder="Enter a URL for the icon"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Summary" label-position="on-border">
                    <b-input
                      v-model="product.summary"
                      placeholder="Enter the product summary"
                      :disabled="disabled"
                      type="textarea"
                    ></b-input>
                  </b-field>
                  <b-field label="Description" label-position="on-border">
                    <b-input
                      v-model="product.description"
                      placeholder="Enter your middle name"
                      :disabled="disabled"
                      type="textarea"
                    ></b-input>
                  </b-field>
                  <b-field label="License" label-position="on-border">
                    <b-input
                      v-model="product.license"
                      placeholder="Enter your middle name"
                      maxlength="2048"
                      type="textarea"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Download" label-position="on-border">
                    <b-input
                      v-model="product.downloadLink"
                      placeholder="Download URL"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Tags" label-position="on-border">
                    <b-taginput
                      v-model="product.tags"
                      :data="allTags"
                      autocomplete
                      :allow-new="false"
                      placeholder=""
                      :disabled="disabled"
                      :open-on-focus="true"
                      @typing="getFilteredTags"
                    ></b-taginput>
                  </b-field>
                </div>
                <div class="level is-mobile">
                  <div class="level-left"></div>
                  <div class="level-right">
                    <div class="field is-grouped">
                      <button
                        type="sumbit"
                        class="button is-link"
                        :disabled="disabled"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-tab-item>
            <b-tab-item label="History" :visible="product.productId != null">
              <history-list
                :session="session"
                :product="product"
              ></history-list>
            </b-tab-item>
            <b-tab-item label="Preview">
              <product-detail
                :session="session"
                :product="product"
              ></product-detail>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";
import HistoryList from "./ProductHistoryList";
import ProductDetail from "@/Druware.Web.Shared/components/ProductDetail";

const url = "/api/product/";

export default {
  name: "view-admin-product-edit",
  components: {
    BCard,
    ProductDetail,
    HistoryList
  },
  data() {
    return {
      session: {},
      product: {},
      productTags: [],
      history: [],
      error: "",
      disabled: false,
      activeTab: undefined,
      allTags: [],
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 10
    };
  },
  methods: {
    getFilteredTags(text) {
      var result = this.allTags.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        );
      });
      return result;
    },
    onSubmit(e) {
      e.preventDefault();
      this.disabled = true;
      if (this.product.productId > 0) {
        API.put(
          url,
          this,
          this.product.productId,
          this.product,
          function(obj) {
            obj.disabled = false;
            obj.showMessage("Changes Saved!.", "is-success");
          },
          function(obj, err) {
            obj.disabled = false;
            obj.showMessage("Server returned an error: " + err);
            obj.$router.back();
          }
        );
      } else {
        API.post(
          url,
          this,
          this.product,
          function(obj, data) {
            obj.disabled = false;
            if (data.login == null) {
              obj.showMessage("Unable to create this product.");
              return;
            }
            obj.showMessage("Changes Saved!.", "is-success");
            obj.$router.back();
          },
          function(obj, err) {
            obj.disabled = false;
            obj.showMessage("Server returned an error: " + err);
          }
        );
      }
    },
    onCancel() {
      this.$router.back();
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    },
    stringsForTags(tagList) {
      var result = [];
      tagList.forEach(function(item) {
        result.push(item.tag != null ? item.tag.name : item.name);
      });
      return result;
    },
    formatDate(str) {
      var d = new Date(str);
      return d.toLocaleDateString("en-US");
    },
    getHistory(productId) {
      API.list(
        url + productId + "/history",
        this,
        function(obj, data) {
          obj.history = data.list;
        },
        function(obj, err) {
          obj.showMessage(err);
        }
      );
    }
  },
  mounted() {
    this.session = this.$attrs.session;
    API.list(
      API.url.base.tag,
      this,
      function(obj, data) {
        // alter the tags from the list to an array of strings.
        obj.allTags = obj.stringsForTags(data.list);
      },
      function(obj, err) {
        obj.showMessage(err);
      }
    );
    API.get(
      url,
      this,
      this.$route.params.short,
      function(obj, data) {
        obj.product = data;
        obj.getHistory(data.productId);
      },
      function(obj, err) {
        obj.showMessage(err);
      }
    );
  }
};
</script>
