<template>
  <div id="view-admin-asset-edit">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <b-card title="Asset Edit" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" alt="Configuration Icon" />
          </figure>
        </div>
        <div class="media-content">
          <form action="" @submit="onSubmit">
            <b-tabs
              v-model="activeTab"
              size="is-small"
              type="is-boxed"
              position="is-centered"
            >
              <b-tab-item label="Asset">
                <div class="content">
                  <b-field label="Description" label-position="on-border">
                    <b-input
                      ref="desc"
                      v-model="asset.description"
                      required
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="File Name" label-position="on-border">
                    <b-input
                      v-model="asset.fileName"
                      required
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field class="file is-primary is-light">
                    <b-upload
                      v-model="file"
                      class="file-label"
                      @input="onFileChange"
                    >
                      <span class="file-cta">
                        <span class="file-label">Upload</span>
                      </span>
                      <span class="file-name" v-if="file">
                        {{ file.name }}
                      </span>
                    </b-upload>
                  </b-field>
                  <b-field label="Type" label-position="on-border">
                    <b-input
                      v-model="asset.mediaType"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Type" label-position="on-border">
                        <b-select
                          required
                          v-model="asset.typeId"
                          :disabled="disabled"
                        >
                          <option
                            v-for="t in types"
                            :value="t.typeId"
                            :key="t.typeId"
                          >
                            {{ t.description }}
                          </option>
                        </b-select>
                        <router-link
                          to="/admin/asset/type"
                          class="button is-info is-light ml-1"
                          >Edit</router-link
                        >
                      </b-field>
                    </div>
                  </div>
                </div>
              </b-tab-item>
              <b-tab-item label="Preview" v-if="asset.assetId != null">
                <div class="has-text-centered">
                  <img :src="previewPath" class="m-0 p-0 has-shadow" />
                </div>
              </b-tab-item>
            </b-tabs>
            <div class="level is-mobile">
              <div class="level-left">
                <small v-if="error != ''" class="level-item is-danger">
                  {{ error }}
                </small>
              </div>
              <div class="level-right">
                <div class="field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link"
                    :disabled="disabled"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-admin-asset-edit",
  components: {
    BCard
  },
  data() {
    return {
      session: {},
      asset: {},
      isLoading: false,
      file: null,
      postedDate: null,
      types: [],
      disabled: false,
      activeTab: undefined,
      error: null
    };
  },
  computed: {
    previewPath() {
      return this.asset.fileName == null
        ? ""
        : "/api/asset/" + this.asset.fileName;
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (this.session.login != null) {
        this.disabled = true;
        if (this.$route.params.fileName != null) {
          // edit
          API.put(
            API.url.base.asset,
            this,
            this.asset.assetId,
            this.asset,
            function(obj, data) {
              obj.disabled = false;
              obj.asset = data;
              obj.onDoUpload();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        } else {
          // add
          API.post(
            API.url.base.asset,
            this,
            this.asset,
            function(obj, data) {
              obj.disabled = false;
              obj.asset = data;
              obj.onDoUpload();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        }
      }
    },
    onDoUpload() {
      if (this.file == null) {
        this.showMessage("Changes Saved!.", "is-success");
        this.$router.back();
      }
      const form = new FormData();
      form.append("content", this.file, this.file.name);
      API.put(
        API.url.base.asset,
        this,
        this.asset.assetId + "/content",
        form,
        function(obj, data) {
          obj.asset = data;
          obj.showMessage("Changes Saved!.", "is-success");
          obj.$router.back();
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showMessage("Server returned an error: " + err);
        }
      );
    },
    onCancel() {
      this.$router.back();
    },
    onFileChange() {
      this.asset.fileName = this.file.name;
      var ext = this.file.name.substr(this.file.name.lastIndexOf(".") + 1);
      switch (ext) {
        case "gif":
          this.asset.mediaType = "image/gif";
          break;
        case "jpg":
          this.asset.mediaType = "image/jpeg";
          break;
        case "jpeg":
          this.asset.mediaType = "image/jpeg";
          break;
        case "png":
          this.asset.mediaType = "image/png";
          break;
        default:
          this.asset.mediaType = "";
          break;
      }
    },
    onBodyChange() {
      if (this.asset == null) return;
      if (this.asset.summary == "" || this.asset.summary == null) {
        this.asset.summary = this.asset.body.substring(0, 2014);
      }
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  mounted() {
    this.isLoading = true;
    this.session = this.$attrs.session;

    API.list(
      API.url.base.assetType,
      this,
      function(obj, data) {
        obj.types = data.list;
      },
      function(obj, err) {
        obj.showMessage("Unable to get types: " + err, "is-danger");
      }
    );
    var fName = this.$route.params.fileName;
    if (fName != null && fName != "new") {
      API.get(
        API.url.base.asset,
        this,
        this.$route.params.fileName + ".json",
        function(obj, data) {
          obj.asset = data;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showMessage("Unable to get asset: " + err, "is-danger");
        }
      );
      return;
    }
    API.model(
      API.url.base.asset,
      this,
      function(obj, data) {
        obj.asset = data;
        obj.isLoading = false;
      },
      function(obj, err) {
        obj.showMessage("Unable to get model: " + err, "is-danger");
      }
    );
  }
};
</script>

<style lang="scss" scoped>
.-has-shadow {
  border-radius: 5px 80px;
  box-shadow: 10px 10px 25px rgb(32, 32, 32);
}
</style>
