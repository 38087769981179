<template>
  <div id="view-admin-picklist-type-management">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">{{ this.displayName }} Management</div>
            <div class="level">
              <div class="level-left">
                <b-select
                  v-model="perPage"
                  :disabled="!isPaginated"
                  class="level-item"
                >
                  <option value="5">5 per page</option>
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="20">20 per page</option>
                </b-select>
              </div>
              <div class="level-right">
                <a @click="onAdd()" class="button is-small is-success is-light"
                  >New</a
                >
              </div>
            </div>
            <section>
              <b-loading
                :is-full-page="false"
                v-model="isLoading"
                :can-cancel="false"
              ></b-loading>

              <b-table
                :data="list"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                striped
                default-sort="name"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                icon-pack="fas"
              >
                <b-table-column
                  field="id"
                  label="ID"
                  width="30"
                  numeric
                  v-slot="props"
                >
                  {{ props.row.tagId }}
                </b-table-column>

                <b-table-column
                  field="name"
                  width="auto"
                  label="Name"
                  v-slot="props"
                >
                  {{ props.row.name }}
                </b-table-column>

                <b-table-column label="Tools" v-slot="props" sticky width="140">
                  <span>
                    <a
                      @click="onAdd(props.row)"
                      class="button is-small is-success is-light mr-1"
                      >Edit</a
                    >
                    <a
                      @click="onDelete(props.row)"
                      class="button is-small is-danger is-light"
                      >Delete</a
                    >
                  </span>
                </b-table-column>
              </b-table>
            </section>
          </div>
          <div class="footnotes is-hidden">
            <ol>
              <li id="fn:1"></li>
            </ol>
          </div>
        </div>
      </div>
    </article>
    <b-modal
      v-model="isAddDialogActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      width="80%"
      scroll="keep"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <add-dialog
          :session="session"
          :edit="editItem"
          @onComplete="onAddComplete"
          @onCancel="onAddCancel"
        ></add-dialog>
      </template>
    </b-modal>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import AddDialog from "./TagEdit";

export default {
  name: "view-admin-tag-managment",
  components: {
    AddDialog
  },
  data: function() {
    return {
      displayName: "",
      type: "",
      apiUrl: "",
      isAddDialogActive: false,
      editItem: null,
      list: [],
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 10
    };
  },
  props: {
    session: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    onAdd(obj = null) {
      this.editItem = obj;
      this.isAddDialogActive = true;
    },
    onAddComplete(list = null) {
      if (list == null) {
        this.getList();
      }
      this.isAddDialogActive = false;
    },
    onAddCancel() {
      this.isAddDialogActive = false;
    },
    onDelete(obj) {
      var msg = 'Are you sure you want to <b>delete</b> "';
      msg += obj.name + '"? This action cannot be undone.';

      this.$buefy.dialog.confirm({
        title: "Deleting Tag",
        message: msg,
        confirmText: "Delete Tag",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          API.delete(
            API.url.base.tag,
            this,
            obj.tagId,
            function(obj) {
              obj.getList();
              obj.$buefy.toast.open("Tag deleted!");
            },
            function(obj, err) {
              obj.showError(err);
            }
          );
        }
      });
    },
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    },
    getList() {
      this.isLoading = true;
      API.list(
        API.url.base.tag,
        this,
        function(obj, data) {
          obj.list = data.list;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showError(err);
          obj.isLoading = false;
        }
      );
    }
  },
  mounted() {
    this.getList();
  }
};
</script>
